import { useIntersectionObserver } from "@vueuse/core";
export default {
  install(app) {
    app.directive("lazySrc", {
      mounted(el, binding) {
        let imgDefault =
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
        el.src = imgDefault; // 指定默认显示内容
        const { stop } = useIntersectionObserver(
          el,
          ([{ isIntersecting }]) => {
            if (isIntersecting) {
              // 如果在可见区域中
              el.onerror = () => {
                //如图片加载失败
                el.src = imgDefault;
              };
              stop(); //如已在可见区域内则下次不再监听
              //set url
              el.src = binding.value;
              console.log(el.src + "加载成功");
              //set style
              el.className = "imgFade";
            }
          },
          { threshold: 0 }
        ); // 当可视区域宽高为0就触发
        //console.log(el, binding.value)
      },
    });
  },
};
