import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect:"/home"
  },
  {
    path: '/home',
    name: '首页',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/Home.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
